<template>
  <section class="store-list">
    <h2>가게 목록</h2>
    <div class="stores">
      <div class="store" v-for="store in stores" :key="store.id">
        <img :src="store.image" :alt="store.name" class="store-image">
        <h3>{{ store.name }}</h3>
        <div class="store-info">
          <div class="icons">
            <span class="rating">
              <i class="fa fa-star" aria-hidden="true"></i> {{ store.rating }} / 5.0
            </span>
            <span class="likes">
              <i class="fa fa-heart" aria-hidden="true"></i> {{ store.likes }}
            </span>
          </div>
        </div>
        <div class="price-info">
          <span class="discount-rate" v-if="store.discountRate">{{ store.discountRate }}% OFF</span>
          <span class="original-price">{{ formatPrice(store.originalPrice) }}</span>
          <span class="discounted-price">{{ formatPrice(store.discountedPrice) }}</span>
        </div>
      </div>
    </div>
  </section>
  <!-- 후기 섹션 추가 -->
  <section class="reviews mt-5">
    <h3>최근 후기</h3>
    <div class="px-3"> <!-- 여백을 추가하는 div -->  
      <table class="table">
        <thead>
          <tr>
            <th>날짜</th>
            <th>제목</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="review in recentReviews" :key="review.id">
            <td>{{ review.date }}</td>
            <td><a :href="review.link">{{ review.title }}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
      <a href="#" @click="showAllReviews">전체 보기</a>
  </section>
</template>

<script>
import { stores } from '../assets/stores.js';
import { reviews } from '../assets/reviews.js'; // 후기 데이터 불러오기

export default {
  name: 'StoreList',
  data() {
    return {
      stores,
      allReviews: reviews, // 모든 후기 데이터
      showAll: false, // 전체 후기 보기 상태
    };
  },
  computed: {
    recentReviews() {
      return this.showAll ? this.allReviews : this.allReviews.slice(0, 3);
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(value);
    },
    showAllReviews(event) {
      event.preventDefault();
      this.showAll = true;
    },
  },
}
</script>

<style src="../assets/StoreListStyles.css" scoped></style>
