<template>
  <div class="home-page">
    <header class="site-header">
      <h1>마월에 오신 것을 환영합니다!</h1>
      <p>전국 최고의 마사지 서비스를 제공합니다.</p>
    </header>
    <section class="feature-image">
      <img src='https://source.unsplash.com/640x480/?wellness' alt="Featured Massage">
      <h2>휴식과 치유의 시간</h2>
      <p>고품질의 마사지로 일상의 스트레스에서 벗어나세요.</p>
    </section>
    <!-- 서비스 소개 섹션 -->
    <section class="service-intro">
      <h2>저희 서비스를 소개합니다</h2>
      <p>고객님의 건강과 행복을 위해 최선을 다하는 마월 마사지 서비스입니다.</p>
    </section>

    <!-- 서비스 목록 섹션 -->
    <section class="service-list">
      <h3>서비스 목록</h3>
      <ul>
        <li>스웨디시 마사지</li>
        <li>건식 마사지</li>
        <li>아로마 테라피</li>
      </ul>
    </section>

    <!-- 가게 목록 섹션 -->
    <StoreList />
  </div>
</template>

<script>
// StoreList 컴포넌트 import
import StoreList from './StoreList.vue'

export default {
  name: 'HomePage',
  components: {
    // StoreList 컴포넌트 등록
    StoreList
  },
}
</script>

<style scoped>
.home-page {
  text-align: center;
  color: #333;
}
.site-header {
  background-color: #0099FF;
  color: white;
  padding: 20px;
}
.feature-image img {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
}

.service-intro, .service-list {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.service-list h3 {
  margin-bottom: 20px;
}

.service-list ul {
  list-style-type: none; /* 리스트 스타일 제거 */
  padding: 0;
  margin: 0 auto; /* 가운데 정렬 */
  max-width: 600px; /* 최대 너비 설정 */
}

.service-list li {
  background-color: #0099FF;
  color: white;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
</style>
